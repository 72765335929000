<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle" style="text-align: center">{{ list.Title }}</div>
      <div style="display: flex; justify-content: space-between">
        <div v-if="list.AData" class="rikind">{{ list.AData }}</div>
        <div v-if="list.ADate" class="rikind">{{ list.ADate }}</div>
        <div v-if="list.Address" class="rikind">{{ list.Address }}</div>
      </div>
      <div style="display: flex; justify-content: space-between">
        <div v-if="list.Host" class="rikind">主持人:{{ list.Host }}</div>
        <div v-if="list.JoinMan" class="rikind">
          参加人员:{{ list.JoinMan }}
        </div>
      </div>
      <!-- 轮播图 -->
      <van-swipe
        v-if="list.Imgs"
        class="homebanner"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item v-for="banner in list.Imgs.split(',')" :key="banner"
          ><img
            :src="banner"
            style="height: 172px; width: 100%; border-radius: 10px"
          />
        </van-swipe-item>
      </van-swipe>
      <!-- <div v-if="list.Imgs" class="essayImg" style="width: 100%">
        <img
          style="height: 120px; width: 45%"
          v-for="(item, index) in list.Imgs.split(',')"
          :key="index"
          :src="item"
          alt=""
          @click="thematic(list.Imgs.split(','))"
        />
      </div> -->
      <!-- 内容 -->
      <div
        class="essayContent"
        v-html="list.Content"
        @click="imageEnlargement"
      ></div>
      <!-- 附件 -->
      <div
        class="video"
        style="text-align: right; margin: 30px 0"
        v-if="list.Annex"
      >
        <a
          style="font-size: 14px; color: #4f4fef"
          :href="list.Annex"
          target="_blank"
          >附件地址</a
        >
      </div>
      <!--  -->
      <!-- 视频 -->
      <div class="video" v-if="list.Video" style="text-align: center">
        <video
          style="width: 100%; height: 220px"
          :src="list.Video"
          controls="controls"
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <!-- <div class="rikind"
        style="text-align: right;">阅读：{{ list.ReadNum }}</div> -->
      <div style="position: fixed; bottom: 0; width: 95%" v-if="add == 1">
        <van-button class="button" @click="Activity" round type="info"
          >活动报名</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
var formatNum = function (num) {
  return num < 10 ? "0" + num : num;
};
import { ImagePreview } from "vant";
import { WeGetActivityDetailPB, WxEnrolPBActivity } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import { WxGetActivityDetail, WxGetPbActElegantDetail } from "@/api/RealInfo";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
      pdf: "",
      nowDate: "",
      add: "",
    };
  },
  created() {
    console.log(this.$route);
    // 获取并保存openid
    if (this.$route.params["openid"]) {
      setOpenId(this.$route.params["openid"]);
    }
  },
  methods: {
    thematic(e) {
      ImagePreview({
        images: e, // 传递的是图片集数组
        startPosition: 0, // 图片索引位置
        closeable: true, // 是否显示关了按钮
        showIndicators: true, // 是否显示轮播指示器
        loop: false, // 不设置为false,首图和尾部图不能正常加载
      });
    },
    // 富文本图片内容 点击放大
    imageEnlargement(e) {
      if (e.target.nodeName === "IMG") {
        ImagePreview({
          images: [e.target.src], // 传递的是图片集数组
          startPosition: 0, // 图片索引位置
          closeable: true, // 是否显示关了按钮
          showIndicators: true, // 是否显示轮播指示器
          loop: false, // 不设置为false,首图和尾部图不能正常加载
        });
      }
    },
    // 获取当前时间
    dataNow() {
      var nowDate = new Date();
      var year = nowDate.getFullYear();
      var month = formatNum(nowDate.getMonth() + 1);
      var day = formatNum(nowDate.getDate());
      var hour = formatNum(nowDate.getHours());
      var min = formatNum(nowDate.getMinutes());
      var sec = formatNum(nowDate.getSeconds());
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      };
      this.nowDate =
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
    },
    // 获取活动详情
    getRealInfo() {
      this.aids = this.$route.params.Id.split("-")[0];
      this.add = this.$route.params.Id.split("-")[1];
      // this.aids = this.$route.params.Id;
      if (this.add == 1) {
        WxGetActivityDetail({
          openID: getOpenId(),
          aId: this.aids,
        }).then((res) => {
          this.list = res.data.data;
          if (res.data.data.EndTime) {
            this.list.EndTime = res.data.data.EndTime.replace("T", " ");
          }
        });
      } else {
        WxGetPbActElegantDetail({
          openID: getOpenId(),
          aeId: this.aids,
        }).then((res) => {
          this.list = res.data.data;
          if (res.data.data.EndTime) {
            this.list.EndTime = res.data.data.EndTime.replace("T", " ");
          }
        });
      }
    },
    // 报名参加活动
    Activity() {
      this.ActivityFrom.AId = this.aids;
      this.ActivityFrom.OpenID = getOpenId();
      // this.ActivityFrom.OpenID = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      this.ActivityFrom.pAKind = this.list.PAKind;
      WxEnrolPBActivity(this.ActivityFrom).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Dialog.alert({
            message: "报名成功!",
          }).then(() => {
            // on close
            this.$router.go(-1);
          });
        } else {
          Dialog.alert({
            message: "申请失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
  mounted() {
    this.getRealInfo();
    this.dataNow();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}

.rikind {
  text-align: right;
}
.homebanner {
  width: 95%;
  margin: auto;
  margin-top: 8px;
}
</style>